import React, { Component } from 'react';
import './addproduct.scss'
import axios from 'axios'
import ReactQuill from 'react-quill'
import { Link } from 'react-router-dom'
import 'react-quill/dist/quill.snow.css'
import { url, headers } from '../../config'
import { connect } from 'react-redux'
import _ from 'lodash'

import Header from '../Header'
import Navbar from '../Navbar'
import Loading from '../Loading'

class AddProduct extends Component {
    state = {
        categories: [],
        subcategories: [],
        imagePreview : '',
        imagePreviewTwo:'',
        imagePreviewThree: '',
        message : '',
        messageadd: '',
        allinput: [],
        allsize: [],
        allstock: [],
        size: '',
        stock: '',
        description: '',
        success: false,
        loading: false,
    }

    componentDidMount(){
        let categories = this.props.categories.data
        if(_.isArray(categories)){
            this.setState({ categories })
        }
    }

    selectCategory = (e) => {
        debugger
        this.setState({
            category_id : e.target.value
        })

        let categories = this.state.categories
        let selectcategories = categories.filter((el)=>{
            return(
                el.category_id == e.target.value
            )
        })

        if(!_.isUndefined(selectcategories[0])){
            this.setState({ subcategories: selectcategories[0].subcategories })
        } else {
            this.setState({ subcategories: [] })
        }
    }

    addSize = () => {
        const { allinput, allsize, size, allstock, stock } = this.state

        if(size && stock){
            let input = allinput
            let num = allinput.length
            input.push(num)
            
            let sizes = allsize
            sizes.push(size)
    
            let stocks = allstock
            stocks.push(stock)
    
            this.setState({
                allinput : input,
                allsize : sizes,
                allstock : stocks,
                size: '',
                stock : '',
                messageadd: ''
            })
        } else {
            this.setState({ messageadd: 'Ingrese tamaño y stock' })
        }
    }

    deleteSizes (i) {
        let allsize = this.state.allsize
        let allstock = this.state.allstock 
        let allinput = this.state.allinput   
        

        allsize.splice(i,1)
        allstock.splice(i,1)
        allinput.splice(i,1)

        this.setState({
            allsize,
            allstock,
            allinput
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleImage = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreview: reader.result
            })
        }
    }

    handleImageTwo = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onloadend = () => {
            this.setState({
                fileTwo: file,
                imagePreviewTwo: reader.result
            })
        }
    }

    handleImageThree = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onloadend = () => {
            this.setState({
                fileThree: file,
                imagePreviewThree: reader.result
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { imagePreview, imagePreviewTwo, imagePreviewThree, file, fileTwo, fileThree, allsize, allstock, name, code, category_id, sub_category_id, price, weight, description } = this.state
        const token = this.props.user.token
        
        if(!description) this.setState({ message: 'Descripción es requerido' })
        if(allsize.length<1) this.setState({ message: 'Haga clic en agregar tamaño y stock' })
        if(!weight) this.setState({ message: 'Peso es requerido' })
        if(!price) this.setState({ message: 'Precio es requerido' })
        if(!sub_category_id) this.setState({ message: 'Seleccione Subcategoría' })
        if(!category_id) this.setState({ message: 'Seleccione categoría' })
        if(!code) this.setState({ message: 'El código es requerido' })
        if(!name) this.setState({ message: 'El nombre es requerido' })
        if(!imagePreview) this.setState({ message: 'La imagen es requerido' })
        if(!imagePreviewTwo) this.setState({ message: 'La imagen dos es requerido' })
        if(!imagePreviewThree) this.setState({ message: 'La imagen tres es requerido' })

        this.setState({ messageadd: '' })

        if(imagePreview && name && code && category_id && sub_category_id && price && weight && allsize && allstock && description ) {
            this.setState({ loading: true });

            let data = new FormData();
            data.append('image', file );
            data.append('code', code );
            axios.post( url + "/product/upload-image" , data , headers(token) )
            .then(res=>{
               
                let data = new FormData();
                data.append('image', fileTwo );
                data.append('code', code );
                axios.post( url + "/product/upload-image" , data , headers(token) )
                .then(resTwo => {

                    let data = new FormData();
                    data.append('image', fileThree );
                    data.append('code', code );
                    axios.post( url + "/product/upload-image" , data , headers(token) )
                    .then(resThree => {
                        let data = new FormData();        
                        allsize.map((v , i) => {        
                            data.append("size[]", allsize[i])
                            data.append("stock[]", allstock[i])
                        });                
                        data.append("image", res.data.url);
                        data.append("imagetwo", resTwo.data.url);
                        data.append("imagethree", resThree.data.url);
                        data.append("name", name);
                        data.append("code", code);
                        data.append("category_id", category_id);
                        data.append("sub_category_id", sub_category_id);
                        data.append("price", price);
                        data.append("weight", weight);
                        data.append("description", description);
                
                        axios.post( url + "/product" , data, headers(token) )
                        .then(res=>{
                            this.setState({ 
                                loading: false,
                                success: true, 
                                message: '',
                                imagePreview: '',
                                name: '',
                                code: '',
                                category_id: '',
                                allsize : [],
                                allstock : [],
                                sub_category_id: '',
                                price: '',
                                weight: '',
                                description: '',
                                file: null
                            })
                            setTimeout(() => {
                                this.setState({ success: false })
                            }, 2000);
                        })
                        .catch(err=>{
                            this.setState({ message: 'No se pudo agregar el producto', loading: false })
                        });
                    })
                    .catch(err=>{
                        this.setState({ message: 'Servidor de conexión fallido', loading: false })
                    });
                })
                .catch(err=>{
                    this.setState({ message: 'Servidor de conexión fallido', loading: false })
                });

            })
            .catch(err=>{
                this.setState({ message: 'Servidor de conexión fallido', loading: false })
            });
        }
    }

    render() {
        const { categories, subcategories, loading, imagePreview, imagePreviewTwo, imagePreviewThree, allsize, allstock, name, code, size, stock, category_id, sub_category_id, price, weight, description, message, messageadd, success } = this.state
        return (
            <div className="add-wrapper">
                <Header />
                <Navbar />
                <Link to='/products'>
                    <div className="cancel"><i className="demo-icon icon-cancel">&#xe80f;</i></div>
                </Link>
                { loading ? <Loading /> : '' }

                <div className="add-product">
                    <h1>Agregar producto</h1>
                    { success ? <div className="success"><div>Correcto</div></div> : "" }

                    <div className="photo">
                        <div className="image">
                            { imagePreview ? <img src={imagePreview} alt="imagePreview"/> : <div></div> } 
                        </div>
                        <label htmlFor="photo">Imagen  <i className="demo-icon icon-picture">&#xe812;</i></label><br/>
                        <input onChange={this.handleImage} id="photo" type="file" accept="image/x-png,image/gif,image/jpeg"/>
                    </div>

                    <div className="photo">
                        <div className="image">
                            { imagePreviewTwo ? <img src={imagePreviewTwo} alt="imagePreview"/> : <div></div> } 
                        </div>
                        <label htmlFor="photo">Imagen dos <i className="demo-icon icon-picture">&#xe812;</i></label><br/>
                        <input onChange={this.handleImageTwo} id="photo" type="file" accept="image/x-png,image/gif,image/jpeg"/>
                    </div>

                    <div className="photo">
                        <div className="image">
                            { imagePreviewThree ? <img src={imagePreviewThree} alt="imagePreview"/> : <div></div> } 
                        </div>
                        <label htmlFor="photo">Imagen  <i className="demo-icon icon-picture">&#xe812;</i></label><br/>
                        <input onChange={this.handleImageThree} id="photo" type="file" accept="image/x-png,image/gif,image/jpeg"/>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <label htmlFor="">Nombre</label>
                        <input value={name} onChange={this.handleChange} name="name" type="text"/>
                        <label htmlFor="">Código</label>
                        <input value={code} onChange={this.handleChange} name="code" type="text"/>
                        <label htmlFor="">Categoría</label>
                        <select value={category_id} onChange={this.selectCategory} name="category_id" id="category">
                            <option value="">Seleccionar</option>
                            {
                                categories.map(category=>{
                                    return(
                                        <option key={category.category_id} value={category.category_id}>{category.category_name}</option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="">Subcategoría</label>
                        <select value={sub_category_id} onChange={this.handleChange} name="sub_category_id" id="sub_category">
                            <option value="">Seleccionar</option>
                            {
                                subcategories.map(subcategory=>{
                                    return(
                                        <option key={subcategory.sub_category_id} value={subcategory.sub_category_id}>{subcategory.name}</option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="">Precio</label>
                        <input value={price} placeholder="S/" onChange={this.handleChange} name="price" type="number"/>
                        <label htmlFor="">Peso</label>
                        <input value={weight} placeholder="KG"  onChange={this.handleChange} name="weight" type="number"/>
                        
                        <label htmlFor="">Tamaño y Stock</label>

                        <div className="size-stock">
                            <div>
                                <span>Tamaño</span><br/>
                                <hr/>
                                {
                                    allsize.map((size, i)=>{
                                        return(
                                                <div className="size" key={i}>
                                                    {size}
                                                    <hr/>
                                                </div>
                                        )
                                    })
                                }
                                <input placeholder="Tamaño" onChange={this.handleChange} value={size} name="size" type="text"/>
                            </div>
                            <div>
                                <span>Stock</span><br/>
                                <hr/>
                                {
                                    allstock.map((stock, i)=>{
                                        return(
                                                <div className="stock" key={i}>
                                                    {stock} <span onClick={()=>this.deleteSizes(i)}><i className="demo-icon icon-minus">&#xe814;</i></span>
                                                    <hr/>
                                                </div>
                                        )
                                    })
                                }
                                <input placeholder="Stock de entrada" onChange={this.handleChange} value={stock} name="stock" type="text"/>
                            </div>
                        
                        </div>
                        <span className="message">{messageadd}</span>
                        <span className="add" onClick={this.addSize}>Agregar tamaño</span>

                        <label htmlFor="description">Descripción</label>

                        <ReactQuill className="description" value={description} onChange={ (value)=>this.setState({ description: value }) } id="description"/>

                        <span className="message">{message}</span>
                        <button type="submit">Guardar</button>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return({
        categories : state.categoriesReducer,
        user : state.userReducer
    })
}

export default connect(mapStateToProps )(AddProduct);