import React, { Component } from 'react';
import './update.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import { url, headers } from '../../config'
import { connect } from 'react-redux'
import _ from 'lodash'

import Header from '../Header'
import Navbar from '../Navbar'
import Loading from '../Loading'

class UpdateProduct extends Component {
    state = {
        product: this.props.location.state.product,
        message : '',
        messageadd: '',
        sizes: [],
        stocks: [],
        sizeinput: '',
        stockinput: '',
        description: '',
        success: false,
        loading: false,
        categories: [],
        subcategories: [],
    }

    componentDidMount() {
        let categories = this.props.categories.data
        if(_.isArray(categories)){
            this.setState({ categories })
        }
        this.fetchProduct()
    }

    fetchProduct() {
        if(this.props.location.state.product){
            const { product_id, name, code, category_id, sub_category_id, price, 
                weight, description, image, imagetwo, imagethree, sizes, stocks } = this.props.location.state.product;

                const categories = this.props.categories.data;
                const selectCategories = categories.filter(el=>el.category_id === category_id);
                let selectCategory = [];
                if(!_.isUndefined(selectCategories[0])){
                    selectCategory = selectCategories[0].subcategories;
                }
           

            this.setState({
                sizes,
                stocks,
                name,
                code, 
                category_id, 
                sub_category_id, 
                price, 
                weight, 
                description, 
                image,
                imagetwo,
                imagethree,
                product_id,
                subcategories: selectCategory
            })
        } else {
            let id = this.props.match.params.id
            axios( url + "/product/" + id )
            .then(res=>{
                if(res.data){
    
                    const { product_id, name, code, category_id, sub_category_id, price, weight, description, image, imagetwo, imagethree, sizes, stocks } = res.data
                    this.setState({
                        sizes,
                        stocks,
                        name,
                        code, 
                        category_id, 
                        sub_category_id, 
                        price, 
                        weight, 
                        description, 
                        image,
                        imagetwo, 
                        imagethree,
                        product_id
                    })
                }
            })
        }
    }

    selectCategory = (e) => {
        this.setState({
            category_id : e.target.value
        })

        let categories = this.state.categories
        const selectcategories = categories.filter(el=>el.category_id === parseInt(e.target.value));

        if(!_.isUndefined(selectcategories[0])){
            this.setState({ subcategories: selectcategories[0].subcategories })
        } else {
            this.setState({ subcategories: [] })
        }
    }

    addSize = () => {
        const { sizes, inputsize, stocks, inputstock } = this.state

        if(inputsize && inputstock){
            
            let size = sizes
            size.push(inputsize)
    
            let stock = stocks
            stock.push(inputstock)
    
            this.setState({
                sizes : size,
                stocks : stock,
                inputsize: '',
                inputstock : '',
                messageadd: ''
            })
        } else {
            this.setState({ messageadd: 'Ingrese tamaño y stock' })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    changeSize (e, i) {
        let all = this.state.sizes
        all[i] = e.target.value
        this.setState({
            sizes: all
        })
    }

    changeStock (e, i) {
        let all = this.state.stocks
        all[i] = e.target.value
        this.setState({
            stocks: all
        })
    }

    deleteSizes (i) {
        let allsizes = this.state.sizes
        let allstocks = this.state.stocks    
        
        allsizes.splice(i,1)
        allstocks.splice(i,1)

        this.setState({
            sizes: allsizes,
            stocks: allstocks
        })
    }

    handleImage = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onloadend = () => {
            this.setState({
                file: file,
                image: reader.result
            })
        }
    }

    handleImageTwo = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onloadend = () => {
            this.setState({
                fileTwo: file,
                imagetwo: reader.result
            })
        }
    }

    handleImageThree = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onloadend = () => {
            this.setState({
                fileThree: file,
                imagethree: reader.result
            })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const { image, imagetwo, imagethree, file, fileTwo, fileThree, sizes, stocks, name, code, category_id, sub_category_id, price, weight, description, product_id } = this.state
        const token = this.props.user.token
        let image1Url = image;
        let image2Url = imagetwo;
        let image3Url = imagethree;

        if(!description) this.setState({ message: 'Descripción es requerido' })
        if(sizes.length<1) this.setState({ message: 'Haga clic en agregar tamaño y stock' })
        if(!weight) this.setState({ message: 'Peso es requerido' })
        if(!price) this.setState({ message: 'Precio es requerido' })
        if(!sub_category_id) this.setState({ message: 'Seleccione Subcategoría' })
        if(!category_id) this.setState({ message: 'Seleccione categoría' })
        if(!code) this.setState({ message: 'El código es requerido' })
        if(!name) this.setState({ message: 'El nombre es requerido' })
        if(!image) this.setState({ message: 'La imagen es requerido' })
        if(!imagetwo) this.setState({ message: 'La imagen dos es requerido' })
        if(!imagethree) this.setState({ message: 'La imagen tres es requerido' })
        this.setState({ messageadd: '', loading: true })

        //post data if no change image
        if(!file && !fileTwo && !fileThree && image && name && code && category_id && sub_category_id && price && weight && sizes && stocks && description) {

            let data = { name, code, category_id, sub_category_id, price, weight, description, image, imagetwo, imagethree, size: sizes, stock: stocks }
    
            axios.put( url + "/product/" + product_id , data, headers(token) )
            .then(res=>{
                this.setState({ 
                    loading: false,
                    success: true, 
                    message: '',
                    file: null,
                    fileTwo: null,
                    fileThree: null,
                })
                setTimeout(() => {
                    this.setState({ success: false, product: null })
                }, 2000);
            })
            .catch(err=>{
                this.setState({ message: 'No se pudo agregar el producto', loading: false })
            })
        }

        //Post data if change image

        if(file){
            let data = new FormData()
            data.append('image', file);
            data.append('code', code);
            const responseFile = await axios.post( url + "/product/upload-image" , data , headers(token));
            image1Url = responseFile.data.url;
            this.setState({ image: image1Url }) 
        }

        if(fileTwo){
            let data = new FormData()
            data.append('image', fileTwo);
            data.append('code', code);
            const responseFile = await axios.post( url + "/product/upload-image" , data , headers(token));
            image2Url = responseFile.data.url;      
            this.setState({ imagetwo: image2Url }) 
        }

        if(fileThree){
            let data = new FormData()
            data.append('image', fileThree);
            data.append('code', code);
            const responseFile = await axios.post( url + "/product/upload-image" , data , headers(token));
            image3Url =responseFile.data.url;     
            this.setState({ imagethree: image3Url }) 
        }

        if(name && code && category_id && sub_category_id && price && weight && sizes && stocks && description ) {
            let data = { name, code, category_id, sub_category_id, price, weight, description, 
                image: image1Url, 
                imagetwo: image2Url, 
                imagethree: image3Url, 
                size: sizes, stock: stocks };
    
            axios.put( url + "/product/" + product_id , data, headers(token) )
            .then(res=>{
                this.setState({ 
                    loading: false,
                    success: true, 
                    message: '',
                    file: null,
                    fileTwo: null,
                    fileThree: null,
                })
                setTimeout(() => {
                    this.setState({ success: false, product: null })
                }, 3000);
            })
            .catch(err=>{
                this.setState({ message: 'No se pudo agregar el producto', loading: false })
            });
        } 
        
    }

    render() {
        const { product, product_id, categories, subcategories, loading, inputsize, inputstock, sizes, stocks, message, messageadd, success, name, code, category_id, sub_category_id, price, weight, description, image, imagetwo, imagethree } = this.state
        return (
            <div className="update-product">
                <Header />
                <Navbar />

                <Link to={{ pathname:`/product/${product_id}`, state: product }}>
                    <div className="cancel"><i className="demo-icon icon-cancel">&#xe80f;</i></div>
                </Link>
                
                { loading ? <Loading /> : '' }

                <div className="add-product">
                    <h1>Actualizar producto</h1>
                    { success ? <div className="success"><div>Correcto</div></div> : "" }

                    <div className="photo">
                        <div className="image">
                            <img src={image} alt="imagePreview"/> 
                        </div>
                        <label htmlFor="photo">Imagen  <i className="demo-icon icon-picture">&#xe812;</i></label><br/>
                        <input onChange={this.handleImage} id="photo" type="file" accept="image/x-png,image/gif,image/jpeg"/>
                    </div>

                    <div className="photo">
                        <div className="image">
                            <img src={imagetwo} alt="imagePreview"/> 
                        </div>
                        <label htmlFor="photo">Imagen dos<i className="demo-icon icon-picture">&#xe812;</i></label><br/>
                        <input onChange={this.handleImageTwo} id="photo" type="file" accept="image/x-png,image/gif,image/jpeg"/>
                    </div>

                    <div className="photo">
                        <div className="image">
                            <img src={imagethree} alt="imagePreview"/> 
                        </div>
                        <label htmlFor="photo">Imagen tres <i className="demo-icon icon-picture">&#xe812;</i></label><br/>
                        <input onChange={this.handleImageThree} id="photo" type="file" accept="image/x-png,image/gif,image/jpeg"/>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <label htmlFor="">Nombre</label>
                        <input value={name} onChange={this.handleChange} name="name" type="text"/>
                        <label htmlFor="">Código</label>
                        <input value={code} onChange={this.handleChange} name="code" type="text"/>
                        <label htmlFor="">Categoría</label>
                        <select value={category_id} onChange={this.selectCategory} name="category_id" id="category">
                            <option value="">Seleccionar</option>
                            {
                                categories.map(category=>{
                                    return(
                                        <option key={category.category_id} value={category.category_id}>{category.category_name}</option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="">Subcategoría</label>
                        <select value={sub_category_id} onChange={this.handleChange} name="sub_category_id" id="sub_category">
                            <option value="">Seleccionar</option>
                            {
                                subcategories.map(subcategory=>{
                                    return(
                                        <option key={subcategory.sub_category_id} value={subcategory.sub_category_id}>{subcategory.name}</option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="">Precio</label>
                        <input value={price} placeholder="Rp" onChange={this.handleChange} name="price" type="number"/>
                        <label htmlFor="">Peso</label>
                        <input value={weight} placeholder="gram"  onChange={this.handleChange} name="weight" type="number"/>
                        
                        <label htmlFor="">Tamaño y Stock</label>

                        <div className="size-stock">
                            <div>
                                <span>Tamaño</span><br/>
                                <hr/>
                                {
                                   _.isArray(sizes) && sizes.map((size, i)=>{
                                        return(
                                                <div className="size" key={i}>
                                                    <input value={size} onChange={ (e)=>this.changeSize(e, i) } type="text"/>
                                                    <hr/>
                                                </div>
                                        )
                                    })
                                }
                                <input placeholder="Ingrese tamaño" onChange={(e)=>this.setState({ inputsize: e.target.value })} value={inputsize} name="size" type="text"/>
                            </div>
                            <div>
                                <span>Stock</span><br/>
                                <hr/>
                                {
                                   _.isArray(stocks) && stocks.map((stock, i)=>{
                                        return(
                                                <div className="stock" key={i}>
                                                    <input onChange={(e)=>this.changeStock(e,i)} value={stock} type="text"/> <span onClick={()=>this.deleteSizes(i)}><i className="demo-icon icon-minus">&#xe814;</i></span>
                                                    <hr/>
                                                </div>
                                        )
                                    })
                                }
                                <input placeholder="Ingrese stock" onChange={(e)=> this.setState({ inputstock: e.target.value })} value={inputstock} name="inputstock" type="text"/>
                            </div>
                        
                        </div>
                        <span className="message">{messageadd}</span>
                        <span className="add" onClick={this.addSize}>Agregar tamaño</span>

                        <label htmlFor="description">Descripción</label>

                        <ReactQuill className="description" value={description} onChange={ (value)=>this.setState({ description: value }) } id="description"/>

                        <span className="message">{message}</span>
                        <button type="submit">Guardar</button>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return({
        categories : state.categoriesReducer,
        user : state.userReducer
    })
}

export default connect(mapStateToProps )(UpdateProduct);